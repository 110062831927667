import { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { accountState } from "../../../state/account";

const QuizList = () => {
  const [quizList, setQuizList] = useState([]);
  const account = useRecoilValue(accountState);

  const getQuizList = async (option: string) => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
      let response: any;
      if (option === "all") {
        response = await axios.get(`${serverUrl}/api/quiz/admin/all`);
      } else if (option === "open") {
        response = await axios.get(`${serverUrl}/api/quiz/admin/open`);
      } else if (option === "closed") {
        response = await axios.get(`${serverUrl}/api/quiz/admin/closed`);
      }

      setQuizList(response.data);
      // Handle response if needed
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  const handleStopQuiz = async (quizId: string) => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
      const token = localStorage.getItem("token") ? localStorage.getItem("token") : account.token;
      const response = await axios.post(
        `${serverUrl}/api/quiz/stop-quiz`,
        {
          index: quizId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle response if needed
      alert("Quiz stopped");
      window.location.href = "/mypage";
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  const handleDeleteQuiz = async (quizId: string) => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
      const token = localStorage.getItem("token") ? localStorage.getItem("token") : account.token;
      const response = await axios.post(
        `${serverUrl}/api/quiz/delete-quiz`,
        {
          index: quizId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle response if needed
      console.log(response);
      alert("Quiz deleted");
      window.location.href = "/mypage";
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  useEffect(() => {
    getQuizList("all");
  }, []);

  return (
    <Container>
      <Title>Quiz List</Title>
      <Buttons>
        <Button
          onClick={async () => {
            await getQuizList("all");
          }}
        >
          All Quiz
        </Button>
        <Button
          onClick={async () => {
            await getQuizList("open");
          }}
        >
          Open Quiz
        </Button>
        <Button
          onClick={async () => {
            await getQuizList("closed");
          }}
        >
          Closed Quiz
        </Button>
      </Buttons>
      <QuizContainer>
        {quizList.map((quiz: any, index: number) => (
          <Quiz key={index}>
            <Question>Title: {quiz.question}</Question>
            <Choices>
              {quiz.choice1.length > 0 && (
                <Choice>
                  1. {quiz.choice1} {quiz.choice1_counts}
                </Choice>
              )}
              {quiz.choice2.length > 0 && (
                <Choice>
                  2. {quiz.choice2} {quiz.choice2_counts}
                </Choice>
              )}
              {quiz.choice3.length > 0 && (
                <Choice>
                  3. {quiz.choice3} {quiz.choice3_counts}
                </Choice>
              )}
              {quiz.choice4.length > 0 && (
                <Choice>
                  4. {quiz.choice4} {quiz.choice4_counts}
                </Choice>
              )}
              {quiz.choice5.length > 0 && (
                <Choice>
                  5. {quiz.choice5} {quiz.choice5_counts}
                </Choice>
              )}
            </Choices>
            <Info>
              Closed: {quiz.ended ? "Yes" : "No"}
              <br />
              Opened at: {new Date(quiz.openTime).toLocaleString()}
              <br />
              Close at: {new Date(quiz.closeTime).toLocaleString()}
            </Info>
            <StopQuizButton
              onClick={() => {
                handleStopQuiz(quiz.id);
                getQuizList("all");
              }}
            >
              Stop Quiz
            </StopQuizButton>
            {quiz.ended ? (
              <StopQuizButton
                onClick={() => {
                  handleDeleteQuiz(quiz.id);
                  getQuizList("all");
                }}
              >
                Delete Quiz
              </StopQuizButton>
            ) : null}
          </Quiz>
        ))}
      </QuizContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: ${colors.baseText};
`;

const Buttons = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.baseColor};
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #16a085;
  }
`;

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const Quiz = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  color: ${colors.baseText};
  border-radius: 5px;
  width: 300px;
`;

const Question = styled.h3`
  margin-bottom: 10px;
  color: ${colors.baseText};
`;

const Choices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Choice = styled.h4`
  margin-bottom: 5px;
  color: ${colors.baseText};
`;

const Info = styled.h4``;

const StopQuizButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.baseColor};
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #16a085;
  }
`;

export default QuizList;
