import { Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import About from "./page/About";
import Player from "./page/Player";
import Manager from "./page/Manager";
import styled from "styled-components";
import Team from "./page/Team";
import Login from "./page/Login";
import { RecoilRoot, useRecoilState } from "recoil";
import Mypage from "./page/Mypage";
import Navbar from "./components/Navbar";
import TypeTest from "./page/TypeTest";
import { dataState } from "./state/data";
import { useEffect, useState } from "react";
import { getCommonData } from "./utils/getCommonData";
import LoadingScreen from "./page/Home/components/LoadingScreen";
import HeaderComponent from "./components/Header";
import MiniLeaguePage from "./page/MiniLeague";

function App() {
  const [teamInfo, setTeamInfo] = useState<any | null>(null);
  const [playerInfo, setPlayerInfo] = useState<any | null>(null);
  const [data, setData] = useRecoilState(dataState);

  const getData = async () => {
    const commonData: any = await getCommonData();
    setTeamInfo(commonData.teamInfo);
    setPlayerInfo(commonData.allPlayerInfo);
    setData({
      ...data,
      currentRound: parseInt(commonData.currentRound),
      loaded: true,
      allTeamInfo: commonData.teamInfo,
      playerInfo: commonData.allPlayerInfo,
    });
  };

  useEffect(() => {
    getData().then(() => {});
  }, []);

  if (data.loaded === false || teamInfo === null || playerInfo === null) {
    return <LoadingScreen location="app" />;
  }

  return (
    <RecoilRoot>
      <div className="App">
        <Application>
          <Navbar />
          <HeaderWrapper>
            <HeaderComponent playerInfo={playerInfo} allTeamInfo={teamInfo} />
          </HeaderWrapper>
          <Content>
            <Routes>
              <Route path="" element={<Home playerInfo={playerInfo} allTeamInfo={teamInfo} />} />
              <Route path="home" element={<Home playerInfo={playerInfo} allTeamInfo={teamInfo} />} />
              <Route path="league" element={<MiniLeaguePage />} />
              <Route path="about" element={<About />} />
              <Route path="player" element={<Player playerInfo={playerInfo} allTeamInfo={teamInfo} />} />
              <Route path="team" element={<Team />} />
              <Route path="manager" element={<Manager playerInfo={playerInfo} allTeamInfo={teamInfo} />} />
              <Route path="login" element={<Login />} />
              <Route path="test" element={<TypeTest />} />
              <Route path="mypage" element={<Mypage />} />
            </Routes>
          </Content>
        </Application>
      </div>
    </RecoilRoot>
  );
}

export default App;

const Application = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: white;
  color: black;
`;

const HeaderWrapper = styled.div`
  /* position: fixed; */
  /* top: 0; */
  /* width: 100%; */
  /* z-index: 1000; */

  @media (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  padding-left: 250px;
  flex-grow: 1;
  overflow: auto;
  transition: margin 0.3s;

  @media (max-width: 768px) {
    padding-left: 0;
    margin-top: 5rem;
  }
`;
