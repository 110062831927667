import { useRecoilState, useRecoilValue } from "recoil";
import Navbar from "../../components/Navbar";
import styled from "styled-components";
import { accountState } from "../../state/account";
import axios from "axios";
import { useEffect, useState } from "react";
import Button from "../../components/common/Button";
import CreateQuiz from "./components/CreateQuiz";
import QuizList from "./components/QuizList";
import Container from "../../components/common/Container";
import colors from "../../assets/colors/colors";
import PlayerComment from "./components/PlayerComment";

interface Manager {
  name: string;
  player_first_name: string;
  player_last_name: string;
  player_region_name: string;
  summary_event_rank: number;
  summary_overall_rank: number;
  summary_event_points: number;
  summary_overall_points: number;
  // Add other manager properties as needed
}

function Mypage() {
  const [account, setAccountState] = useRecoilState(accountState);
  const [manager, setManager] = useState<Manager | null>(null);
  const [attendance, setAttendance] = useState<number>(0);
  const [lastAttendance, setLastAttendance] = useState<number>(0);
  const [currentTimestamp, setCurrentTimestamp] = useState<number>(0);
  const [quizScore, setQuizScore] = useState<number>(0);
  const [buttonLabel, setButtonLabel] = useState("Check Attendance");
  const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;

  // const getManagerData = async () => {
  //   try {
  //     const response: any = await axios.get(`http://${serverUrl}/api/manager/${account.fplId}`);
  //     setManager(response.data);
  //   } catch (error) {
  //     console.error("Error fetching manager data: ", error);
  //   }
  // };

  const getAttendance = async () => {
    try {
      const response: any = await axios.get(`${serverUrl}/api/attendance/${account.email}`);
      setAttendance(response.data);
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
    }
  };

  const getLastAttendance = async () => {
    try {
      const response: any = await axios.get(`${serverUrl}/api/last-attendance/${account.email}`);
      setLastAttendance(response.data);
    } catch (error) {
      console.error("Error fetching last attendance data: ", error);
    }
  };

  const getQuizScore = async () => {
    try {
      const response: any = await axios.get(`${serverUrl}/api/quiz-score/${account.email}`);
      setQuizScore(response.data);
    } catch (error) {
      console.error("Error fetching quiz score data: ", error);
    }
  };

  // Function to compute the remaining time
  const computeRemainingTime = () => {
    const leftTime = (lastAttendance + 1000 * 60 * 60 * 24 - currentTimestamp) / 1000;
    if (leftTime <= 0) {
      setButtonLabel("Check Attendance");
    } else {
      setButtonLabel(`Time Left: ${leftTime.toFixed(0)} seconds`);
    }
  };

  const logOut = () => {
    localStorage.clear();
    setAccountState({
      username: "",
      email: "",
      fplId: 0,
      token: "",
      isLoggedIn: false,
    });
    window.location.href = "/";
  };

  useEffect(() => {
    // getManagerData();
    getAttendance();
    getLastAttendance();
    getQuizScore();
    computeRemainingTime(); // Call it here to set the initial button label
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimestamp(Date.now());
      computeRemainingTime();
    }, 1000);
    return () => clearInterval(interval);
  }, [lastAttendance, currentTimestamp]);

  // Define the Attendance Check function
  const checkAttendance = async () => {
    if (buttonLabel === "Check Attendance") {
      // Implement the logic for checking attendance
      console.log("Attendance Checked!");
      const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
      const body = {
        email: account.email,
      };
      const result = await axios.post(`${serverUrl}/api/add-attendance`, body, {
        headers: {
          Authorization: `Bearer ${account.token}`,
          "Content-Type": "application/json",
        },
      });
    }
  };

  const formatDate = (timestamp: any) => {
    return new Date(timestamp).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const getTier = (score: number) => {
    if (score < 10) {
      return "Bronze";
    } else if (score < 20) {
      return "Silver";
    } else if (score < 30) {
      return "Gold";
    } else {
      return "Platinum";
    }
  };

  return (
    <Container>
      <Title>Profile</Title>
      <LogOutButton>
        <Button onClick={logOut}>Log Out</Button>
      </LogOutButton>
      <PlayerComment />
      <CreateQuiz />
      <QuizList />
      <InfoBox>
        <InfoRow>
          <InfoLabel>Username</InfoLabel>
          <InfoText>{account.username}</InfoText>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Tier</InfoLabel>
          <InfoText>{getTier(quizScore + attendance)}</InfoText>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Quiz Score</InfoLabel>
          <InfoText>{quizScore}</InfoText>
        </InfoRow>

        <InfoRow>
          <InfoLabel>Attendance</InfoLabel>
          <InfoText>{attendance}</InfoText>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Last Attendance</InfoLabel>
          <InfoText>{formatDate(lastAttendance)}</InfoText>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Attendance Check (+1 points)</InfoLabel>
          <Button onClick={checkAttendance}>{buttonLabel}</Button>
        </InfoRow>
        {/* ... rest of your InfoBox content */}
      </InfoBox>
      <ManagerCard>
        <ManagerDetail>Manager Name: {manager ? manager.name : "null"}</ManagerDetail>
        <ManagerDetail>Player First Name: {manager ? manager.player_first_name : "null"}</ManagerDetail>
        <ManagerDetail>Player Last Name: {manager ? manager.player_last_name : "null"}</ManagerDetail>
        <ManagerDetail>Player Region Name: {manager ? manager.player_region_name : "null"}</ManagerDetail>
        <ManagerDetail>Summary Event Rank: {manager ? manager.summary_event_rank : "null"}</ManagerDetail>
        <ManagerDetail>Summary Overall Rank: {manager ? manager.summary_overall_rank : "null"}</ManagerDetail>
        <ManagerDetail>Summary Event Points: {manager ? manager.summary_event_points : "null"}</ManagerDetail>
        <ManagerDetail>Summary Overall Points: {manager ? manager.summary_overall_points : "null"}</ManagerDetail>
      </ManagerCard>
    </Container>
  );
}

export default Mypage;

const Title = styled.h1`
  color: ${colors.textBlack};
`;

const InfoBox = styled.div`
  background-color: #2c3e50;
  color: ${colors.baseText};
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const InfoLabel = styled.h3`
  color: #aaa;
  flex-basis: 40%;
  text-align: left;
`;

const InfoText = styled.h3`
  color: white;
  flex-basis: 60%;
  text-align: right;
`;

const ManagerCard = styled.div`
  padding: 20px;
  margin: auto;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;

const ManagerDetail = styled.h3`
  color: ${colors.baseText};
  text-align: left;
  border-bottom: 1px solid #eee;
  padding: 8px 0;
`;

const LogOutButton = styled(Button)`
  margin-top: 20px;
`;
