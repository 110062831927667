import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import colors from "../../../assets/colors/colors";
import { getPlayerImgSrcById } from "../../../utils/getPlayerImgSrcById";
import { getTeamSymbolById } from "../../../utils/getTeamNameById";
import { getTeamLogoById } from "../../../utils/getTeamLogoById";
import FixturesModal from "../../../components/FixturesModal";

interface LiveMatchesProps {
  fixturesData: any; // Replace 'any' with the actual type of your fixturesData
  nextFixturesData: any;
  teamData: any; // Replace 'any' with the actual type of your teamData
  currentRound: number;
}

const LiveMatches: React.FC<LiveMatchesProps> = ({ fixturesData, nextFixturesData, teamData, currentRound }) => {
  const [isFixturesModalOpen, setIsFixturesModalOpen] = useState(false);
  const [fixtureData, setFixtureData] = useState<any>([]);
  const handleOpenFixturesModal = (data: any) => {
    setIsFixturesModalOpen(true);
    setFixtureData(data);
  };

  const handleCloseFixturesModal = () => {
    setIsFixturesModalOpen(false);
  };

  const getLeftTime = (time: string) => {
    const date = new Date(time);
    const now = new Date();
    const diff = date.getTime() - now.getTime();

    if (diff <= 0) {
      return "Starting soon";
    }

    const diffDays = Math.floor(diff / (1000 * 3600 * 24));
    const diffHours = Math.floor((diff % (1000 * 3600 * 24)) / (1000 * 3600));
    const diffMinutes = Math.floor((diff % (1000 * 3600)) / (1000 * 60));

    let timeString = "";

    if (diffDays > 0) timeString += `${diffDays}D `;
    if (diffHours > 0 || diffDays > 0) timeString += `${diffHours}H `;
    if (diffMinutes > 0 || diffHours > 0 || diffDays > 0) timeString += `${diffMinutes}M`;

    return `${timeString} left`;
  };

  const innerRoundFixtureData = (data: any) => {
    return (
      <RoundDataContainer
        onClick={() => {
          handleOpenFixturesModal(data);
        }}
      >
        <RoundTeamName>{getTeamSymbolById(teamData, data.team_h)}</RoundTeamName>
        <RoundTeamLogo src={getTeamLogoById(teamData, data.team_h, "id")} alt="logo"></RoundTeamLogo>
        {data.team_h_score || data.team_a_score ? (
          <RoundScore>
            {data.team_h_score} - {data.team_a_score}
          </RoundScore>
        ) : (
          <KickOffTime>{getLeftTime(data.kickoff_time)}</KickOffTime>
        )}
        <RoundTeamLogo src={getTeamLogoById(teamData, data.team_a, "id")} alt="logo"></RoundTeamLogo>
        <RoundTeamName>{getTeamSymbolById(teamData, data.team_a)}</RoundTeamName>
      </RoundDataContainer>
    );
  };

  const getLiveMatches = () => {
    return fixturesData.filter((fixture: any) => fixture.started === true && fixture.finished === false);
  };

  const getUpcomingMatches = () => {
    return fixturesData.filter((fixture: any) => fixture.started === false && fixture.finished === false);
  };

  return (
    <Container>
      {/* {isFixturesModalOpen && (
        <FixturesModal
          isOpen={isFixturesModalOpen}
          onClose={handleCloseFixturesModal}
          allTeamInfo={teamData}
          fixturesInfo={fixturesData}
          currentRound={currentRound}
        />
      )} */}
      {
        // If there are no live matches, display a message
        getLiveMatches().length === 0 ? (
          <>
            <Title>Upcoming matches</Title>
            {getUpcomingMatches().length !== 0 ? (
              <>
                {fixturesData.map((fixture: any) => {
                  return fixture.started === false && fixture.finished === false && innerRoundFixtureData(fixture);
                })}
              </>
            ) : (
              <>
                {nextFixturesData.map((fixture: any) => {
                  return fixture.started === false && fixture.finished === false && innerRoundFixtureData(fixture);
                })}
              </>
            )}
          </>
        ) : (
          <>
            <Title>Live Matches</Title>
            {fixturesData.map((fixture: any) => {
              return fixture.started === true && fixture.finished === false && innerRoundFixtureData(fixture);
            })}
          </>
        )
      }
    </Container>
  );
};

export default LiveMatches;

const Container = styled.div`
  background-color: ${colors.infoBox};
  color: black;
  /* padding: 2rem; */
  margin: 1rem auto; // Center the box
  border-radius: 8px; // Softer border radius
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Adds depth with a shadow
  position: relative; // Needed for the tooltip arrow
  /* max-width: 600px; // Set a max-width for better readability */
  text-align: center; // Align text to the left for better readability
  margin-right: 1rem;
  margin-top: 2rem;
  width: 40%;

  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.baseColor};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const RoundDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3rem;
`;

const RoundTeamName = styled.div`
  width: 20%;
  justify-content: center;
  font-size: 1.2rem;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

const RoundTeamLogo = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: 20%;

  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const RoundScore = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const KickOffTime = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  width: 20%;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
