import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../../assets/colors/colors";
import { useRecoilState } from "recoil";
import { optionState } from "../../../state/option";
import Container from "../../../components/common/Container";
import axios from "axios";
import { getPlayerImgSrcById } from "../../../utils/getPlayerImgSrcById";
import { NumericText, fontSize } from "../../../assets/style/font";
import { Title } from "../../../assets/style/Title";
import { Components } from "../../../assets/style/Components";
import InfoBox from "../../../components/common/InfoBox";

const Transfer = () => {
  const [option, setOption] = useRecoilState(optionState);
  const [transferInData, setTransferInData] = useState([]);
  const [transferOutData, setTransferOutData] = useState([]);
  const [index, setIndex] = useState(0);

  const collectData = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const transferInResponse = await axios.get(`${serverUrl}/api/player/top-players/transfer-in`);
    setTransferInData(transferInResponse.data.slice(0, 3));

    const transferOutResponse = await axios.get(`${serverUrl}/api/player/top-players/transfer-out`);
    setTransferOutData(transferOutResponse.data.slice(0, 3));
  };

  useEffect(() => {
    collectData();
  }, []);

  return (
    <TransferContainer>
      <TransferTitle>Top Transfers</TransferTitle>
      <TransferComponents>
        <Options>
          <Option
            selected={index === 0}
            onClick={() => {
              setOption({
                ...option,
                playerMenuOption: 2,
              });
              setIndex(0);
            }}
          >
            Transfer In
          </Option>
          <Bar>|</Bar>
          <Option
            selected={index === 1}
            onClick={() => {
              setOption({
                ...option,
                playerMenuOption: 3,
              });
              setIndex(1);
            }}
          >
            Transfer Out
          </Option>
        </Options>
        <PodiumBody>
          {(index === 0 ? transferInData : transferOutData).map((player: any, idx) => (
            <Player key={player.id} rank={idx}>
              <PlayerImg src={getPlayerImgSrcById(player.code)} alt={player.web_name} />
              <PlayerName>{player.web_name}</PlayerName>
              <PlayerPoints>{index === 0 ? player.transfers_in_event : player.transfers_out_event}</PlayerPoints>
            </Player>
          ))}
        </PodiumBody>
        <StyledRouter to="/player">Click to view Full chart</StyledRouter>
      </TransferComponents>
    </TransferContainer>
  );
};

const TransferContainer = styled(InfoBox)`
  border-radius: 1rem;
  background-color: ${colors.bannerBackground3};
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const TransferTitle = styled(Title)`
  text-align: center;
  color: ${colors.textBlack};
  margin-bottom: 1rem;
`;

const TransferComponents = styled(Components)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  margin: 0rem auto;
  width: 100%;
`;

const Options = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Option = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  padding: 0.5rem;
  margin: 0 0.5rem;
  background-color: ${({ selected }) => (selected ? colors.buttonHover : colors.buttonBase)};
  color: ${({ selected }) => (selected ? colors.textWhite : colors.textBlack)};

  /* color: ${colors.buttonText}; */
  text-align: center;
  font-size: ${fontSize.media_large.medium};
  border-radius: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.buttonHover};
    transform: translateY(-0.1rem);
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
    padding: 0.5rem;
    width: 45%;
  }
`;

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
  font-size: 1.3rem;
  font-weight: bold;
  color: ${colors.baseText};

  @media (max-width: 768px) {
    font-size: 1rem;
    width: 5%;
  }
`;

// Adjusted styles for the podium look
const PodiumBody = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1rem;
`;

const playerRankStyles: any = {
  0: css`
    order: 2;
    transform: translateY(-1rem);
  `,
  1: css`
    order: 1;
  `,
  2: css`
    order: 3;
  `,
};

const Player = styled.div<{ rank: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.textBlack};
  text-align: center;
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
  ${({ rank }) => playerRankStyles[rank]}

  img {
    width: 5rem;
    margin-bottom: 0.5rem;

    @media (max-width: 500px) {
      width: 3rem;
    }
  }

  @media (max-width: 500px) {
    width: 4rem;
  }
`;
const PlayerImg = styled.img`
  width: 4rem;
  border-radius: 50%;

  @media (max-width: 500px) {
    width: 3rem;
  }
`;

const PlayerName = styled.div`
  font-size: ${fontSize.media_large.small};
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const PlayerPoints = styled(NumericText)`
  font-size: 1rem;
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

const StyledRouter = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  padding: 0.5rem;
  background-color: ${colors.buttonBase};
  color: ${colors.textBlack};
  text-align: center;
  font-size: ${fontSize.media_large.small};
  font-weight: bold;
  border-radius: 1rem;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.buttonHover};
    transform: translateY(-0.1rem);
  }

  @media (max-width: 1268px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    margin: 0.5rem 0;
    width: 90%;
  }
`;

export default Transfer;
