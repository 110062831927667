import axios from "axios";

export const getTopManagerWorld = async () => {
  try {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const response: any = await axios.get(`${serverUrl}/api/manager/top-managers`);
    return response.data.standings.results;
  } catch (error) {
    console.error("Error fetching top manager world:", error);
  }
};
