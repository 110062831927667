import React, { useState } from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import colors from "../assets/colors/colors";
import { useRecoilState } from "recoil";
import { optionState } from "../state/option";
import { searchState } from "../state/search";
import { useNavigate } from "react-router-dom";

interface SearchBarProps {
  openModal: (player: string) => void;
  searchManagerId: (managerId: number) => void;
  searchManagerName: (managerName: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ openModal, searchManagerId, searchManagerName }) => {
  const [option, setOption] = useRecoilState(optionState);
  const [search, setSearch] = useRecoilState(searchState);
  const [searchType, setSearchType] = useState("player");
  const [searchTerm, setSearchTerm] = useState(
    searchType === "player" ? search.playerName : searchType === "managerId" ? search.managerId : search.managerName
  );
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchType === "player") {
      openModal(searchTerm);
    } else if (searchType === "managerId") {
      const managerId = parseInt(searchTerm);
      if (managerId) {
        setOption({ ...option, managerMenuOption: "search" });
        setSearch({ ...search, managerId: managerId });
        searchManagerId(managerId);
      }
    } else {
      setOption({ ...option, managerMenuOption: "search" });
      setSearch({ ...search, managerName: searchTerm });
      searchManagerName(searchTerm);
    }
    navigate(`/manager`);
  };

  const searchPlaceHolder = () => {
    if (searchType === "player") return "Enter Player Name (ex) Salah";
    if (searchType === "managerId") return "Enter Manager ID";
    return "Enter Manager Name";
  };

  return (
    <StyledSearchContainer>
      <StyledSearchForm>
        <SearchType>
          <TypeButton
            isActive={searchType === "player"}
            onClick={() => {
              setSearchTerm("");
              setSearchType("player");
            }}
          >
            Player
          </TypeButton>
          <TypeButton
            isActive={searchType === "managerId"}
            onClick={() => {
              setSearchTerm("");
              setSearchType("managerId");
            }}
          >
            Manager Id
          </TypeButton>
        </SearchType>
        <StyledSearchInput
          type="text"
          placeholder={searchPlaceHolder()}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(); // Enter 키가 눌리면 검색 실행
            }
          }}
        />
        <StyledSearchButton onClick={handleSearch}>
          <FaSearch />
        </StyledSearchButton>
      </StyledSearchForm>
    </StyledSearchContainer>
  );
};

export default SearchBar;

// Styled Components
const StyledSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const StyledSearchForm = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.inputBackground};
  /* border: 1px solid ${colors.baseBorder}; */
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  max-width: 600px;
  width: 100%;
`;

const SearchType = styled.div`
  display: flex;
  margin-right: 1rem;
`;

interface TypeButtonProps {
  isActive: boolean;
}

const TypeButton = styled.button<TypeButtonProps>`
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  background: ${(props) => (props.isActive ? colors.buttonActive : colors.inputBackground)};
  color: ${(props) => (props.isActive ? colors.primary : colors.baseText)};
  font-size: 0.875rem;
  /* font-weight: 600; */
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${colors.baseBoxBackground};
  }
`;

const StyledSearchInput = styled.input`
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: ${colors.baseText};
  background: ${colors.inputBackground};
  border: none;
  border-radius: 0.5rem;
  outline: none;

  &:focus {
    /* border: 1px  ${colors.baseBorder}; */
    background: ${colors.baseBoxBackground};
  }
`;

const StyledSearchButton = styled.button`
  padding: 0.5rem 0.75rem;
  margin-left: 0.5rem;
  background: ${colors.primary};
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;

  &:hover {
    background: ${colors.buttonHover};
  }

  svg {
    font-size: 1.25rem;
  }
`;
