import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import ManagerGraph from "./ManagerGraph";
import { getTeamLogoByName, teamNames } from "../../../utils/getTeamLogoById";
import { Title } from "../../../assets/style/Title";
import { FaInfoCircle } from "react-icons/fa";
import ManagerSquad from "./ManagerSquad";

interface ManagerOverallDataProps {
  managerData: any;
}

const ManagerOverallData: React.FC<ManagerOverallDataProps> = ({ managerData }) => {
  const renderDetailItem = (label: string, value: string | number | JSX.Element) => (
    <Detail>
      <DetailLabel>{label}</DetailLabel>
      <DetailValue>{value}</DetailValue>
    </Detail>
  );

  return (
    <Container>
      {managerData ? (
        <ManagerContainer>
          <ManagerGraph managerData={managerData} />
          <Section>
            <TextData>
              <ManagerData>
                {renderDetailItem("Player Name", `${managerData.player_first_name} ${managerData.player_last_name}`)}
                {renderDetailItem("Country", managerData.player_region_name)}
                {renderDetailItem(
                  "Favorite Team",
                  <TeamLogo
                    src={getTeamLogoByName(teamNames[managerData.favorite_team - 1])}
                    alt="Favorite Team Logo"
                  />
                )}
                {renderDetailItem("Overall Rank", managerData.summary_overall_rank)}
                {renderDetailItem("Overall Points", managerData.summary_overall_points)}
                {renderDetailItem("Event Rank", managerData.summary_event_rank || "not yet")}
                {renderDetailItem("Event Points", managerData.summary_event_points)}
                {renderDetailItem("Total Transfers", managerData.last_deadline_total_transfers)}
                {renderDetailItem("Team Value", `€${managerData.last_deadline_value / 10}`)}
                {renderDetailItem("Bank Money", `€${managerData.last_deadline_bank / 10}`)}
                {renderDetailItem("Years Active", managerData.years_active)}
              </ManagerData>
              <ColorLegend>
                <LegendItem>
                  <LegendColor color={colors.score0th} />
                  <LegendText>Legendary (Event Points 15+)</LegendText>
                </LegendItem>
                <LegendItem>
                  <LegendColor color={colors.score1st} />
                  <LegendText>Top Performance (Event Points 10-14)</LegendText>
                </LegendItem>
                <LegendItem>
                  <LegendColor color={colors.score2nd} />
                  <LegendText>Decent Performance (Event Points 5-9)</LegendText>
                </LegendItem>
                <LegendItem>
                  <LegendColor color={colors.score3rd} />
                  <LegendText>Blank (Event Points 4 or less)</LegendText>
                </LegendItem>
              </ColorLegend>
            </TextData>
            <ManagerSquad squad={managerData.squad} bench={managerData.bench} captain={managerData.current_captain} />
          </Section>
        </ManagerContainer>
      ) : (
        <InstructionContainer>
          <InstructionContent>
            <InstructionTitle>
              <InstructionIcon>
                <FaInfoCircle size={30} />
              </InstructionIcon>
              <span>How to search manager info?</span>
            </InstructionTitle>
            <InstructionBody>
              If this is your first time searching:
              <InstructionList>
                <li>Go to the FPL website and find the manager you want to search for.</li>
                <li>Copy the manager ID from the URL.</li>
                <li>Paste the manager ID in the search bar and click the search button.</li>
              </InstructionList>
              If you have searched before:
              <InstructionList>
                <li>Search your manager ID or manager name in the search bar.</li>
              </InstructionList>
            </InstructionBody>
          </InstructionContent>
        </InstructionContainer>
      )}
    </Container>
  );
};

export default ManagerOverallData;

const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const ManagerContainer = styled.div`
  width: 100%;
`;

const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const ManagerData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextData = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
  }
`;

const DetailLabel = styled.span`
  font-weight: bold;
  color: ${colors.menuText};
  font-size: 1rem;
`;

const DetailValue = styled.span`
  color: #333;
  font-size: 0.9rem;

  @media (max-width: 1200px) {
    text-align: left;
  }
`;

const InstructionContent = styled.div`
  text-align: left;
`;

const InstructionTitle = styled(Title)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const InstructionIcon = styled.div`
  margin-right: 1rem;
`;

const InstructionBody = styled.div`
  font-size: 1rem;
  line-height: 1.5;
`;

const InstructionList = styled.ul`
  padding-left: 1.5rem;
  margin-top: 0.5rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

const TeamLogo = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: contain;
`;

// Color Legend Styles
const ColorLegend = styled.div`
  padding: 0.5rem;
  background-color: ${colors.cardBackground};
  border-radius: 8px;
  width: 90%;
  margin: 4rem auto 1rem auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    margin: 2rem auto 1rem auto;
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const LegendColor = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const LegendText = styled.span`
  font-size: 0.9rem;
  color: ${colors.textBlack};
`;
