import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;

export const getTopPointersApi = async (position: string) => {
  const pointers = await axios.get(`${serverUrl}/api/player/top-players/pointers`, {
    params: {
      position: position,
    },
  });
  return pointers.data;
};

export const getWeeklyPointersApi = async (position: string) => {
  const weeklyPointers = await axios.get(`${serverUrl}/api/player/top-players/weekly-pointers`, {
    params: {
      position: position,
    },
  });
  return weeklyPointers.data;
};

export const getRecentPointersApi = async (position: string) => {
  const recentPointers = await axios.get(`${serverUrl}/api/player/top-players/recent-pointers`, {
    params: {
      position: position,
    },
  });
  return recentPointers.data;
};

export const getScorersApi = async (position: string) => {
  const scorers = await axios.get(`${serverUrl}/api/player/top-players/scorers`, {
    params: {
      position: position,
    },
  });
  return scorers.data;
};

export const getAssistersApi = async (position: string) => {
  const assisters = await axios.get(`${serverUrl}/api/player/top-players/assisters`, {
    params: {
      position: position,
    },
  });
  return assisters.data;
};

export const getBonusApi = async (position: string) => {
  const bonus = await axios.get(`${serverUrl}/api/player/top-players/bonus-pointers`, {
    params: {
      position: position,
    },
  });
  return bonus.data;
};

export const getSelectionApi = async (position: string) => {
  const selection = await axios.get(`${serverUrl}/api/player/top-players/selection`, {
    params: {
      position: position,
    },
  });
  return selection.data;
};

export const getExpectedGoalsApi = async (position: string) => {
  const expectedGoals = await axios.get(`${serverUrl}/api/player/top-players/xg`, {
    params: {
      position: position,
    },
  });

  return expectedGoals.data;
};

export const getExpectedAssistsApi = async (position: string) => {
  const expectedAssists = await axios.get(`${serverUrl}/api/player/top-players/xa`, {
    params: {
      position: position,
    },
  });
  return expectedAssists.data;
};

export const getExpectedGoalsInvolvementApi = async (position: string) => {
  const expectedGoalsAssists = await axios.get(`${serverUrl}/api/player/top-players/xgi`, {
    params: {
      position: position,
    },
  });
  return expectedGoalsAssists.data;
};

export const getTopDataApi = async (position: string) => {
  const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
  const pointers = await axios.get(`${serverUrl}/api/player/top-players/pointers`, {
    params: {
      position: position,
    },
  });
  const weeklyPointers = await axios.get(`${serverUrl}/api/player/top-players/weekly-pointers`, {
    params: {
      position: position,
    },
  });
  const recentPointers = await axios.get(`${serverUrl}/api/player/top-players/recent-pointers`, {
    params: {
      position: position,
    },
  });
  const scorers = await axios.get(`${serverUrl}/api/player/top-players/scorers`, {
    params: {
      position: position,
    },
  });
  const assisters = await axios.get(`${serverUrl}/api/player/top-players/assisters`, {
    params: {
      position: position,
    },
  });
  const bonus = await axios.get(`${serverUrl}/api/player/top-players/bonus-pointers`, {
    params: {
      position: position,
    },
  });
  const selection = await axios.get(`${serverUrl}/api/player/top-players/selection`, {
    params: {
      position: position,
    },
  });

  return {
    pointers: pointers.data,
    weeklyPointers: weeklyPointers.data,
    recentPointers: recentPointers.data,
    scorers: scorers.data,
    assisters: assisters.data,
    bonus: bonus.data,
    selection: selection.data,
  };
};
