import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface GraphProps {
  data: Record<string, number>; // data는 객체 형태
}

const CaptainBarChart: React.FC<GraphProps> = ({ data }) => {
  if (typeof data !== "object" || data === null) {
    console.error("Invalid data format. Expected an object.");
    return <div>Invalid data format</div>;
  }

  // 데이터 정렬: value 기준 내림차순 정렬
  const sortedData = Object.entries(data).sort(([, a], [, b]) => b - a); // [캡틴 이름, 값] 형태
  const categories = sortedData.map(([key]) => key); // 정렬된 키(캡틴 이름) 추출
  const values = sortedData.map(([, value]) => value); // 정렬된 값 추출

  const options: ApexOptions = {
    theme: {
      mode: "light",
      palette: "palette1",
    },
    chart: {
      type: "bar",
      height: 250,
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // 수직 막대 그래프
        columnWidth: "55%",
        colors: {
          ranges: [
            { from: 0, to: 3, color: "#05b177" }, // 값이 0~10인 경우
            { from: 3, to: 5, color: "#b2be40" }, // 값이 11~20인 경우
            { from: 5, to: 10, color: "#cf6400" }, // 값이 21~30인 경우
            { from: 10, to: 15, color: "#e00b0b" }, // 값이 21~30인 경우
            { from: 15, to: 20, color: "#e00b0b" }, // 값이 21~30인 경우
            { from: 20, to: 25, color: "#e00b0b" }, // 값이 21~30인 경우
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories, // 정렬된 캡틴 이름들
      labels: {
        style: {
          colors: "black",
          fontFamily: "inherit",
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Managers",
        style: {
          color: "black",
          fontFamily: "inherit",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val} Managers`,
      },
    },
    responsive: [
      {
        breakpoint: 800,
        options: {
          chart: {
            height: 250,
          },
          xaxis: {
            tickAmount: 5,
          },
        },
      },
    ],
    legend: {
      labels: {
        colors: "black",
      },
    },
  };

  const series = [
    {
      name: "Number of Managers",
      data: values, // 정렬된 값들
    },
  ];

  return <ReactApexChart options={options} series={series} type="bar" height={350} />;
};

export default CaptainBarChart;
