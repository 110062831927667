import axios from "axios";

export const getPlayerEvent = async (playerId: string, round: number) => {
  try {
    const serverUrl = process.env.REACT_APP_NEST_SERVER_ADDRESS;
    const url: string = `${serverUrl}/api/v2/player/${playerId}/round/${round}`;
    const response = await axios.get(url, {
      maxRedirects: 0,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data from FPL:", error);
  }
};
