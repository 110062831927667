import React, { useState } from "react";
import styled from "styled-components";
import PlayerCard from "../../../components/PlayerCard";
import PlayerModal from "../../../components/PlayerModal";
import { useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import colors from "../../../assets/colors/colors";
import { fontSize } from "../../../assets/style/font";
import Field from "../../../assets/image/field.jpg";

const ManagerSquad = ({ squad, bench, captain }: any) => {
  const data = useRecoilValue(dataState);
  const [teamData, setTeamData] = useState<any>(null);
  const [playerData, setPlayerData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const goalkeepers = squad.filter((player: any) => player.element_type === 1);
  const defenders = squad.filter((player: any) => player.element_type === 2);
  const midfielders = squad.filter((player: any) => player.element_type === 3);
  const forwards = squad.filter((player: any) => player.element_type === 4);

  // add all event points
  const eventTotalPoints = squad.reduce((acc: number, player: any) => {
    return player.id === captain ? acc + 2 * player.event_points : acc + player.event_points;
  }, 0);

  const benchTotalPoints = bench.reduce((acc: number, player: any) => {
    return acc + player.event_points;
  }, 0);
  //   const eventTotalPoints = squad.map((player: any) => player.event_points);
  const handleOpenModal = () => {
    setIsModalOpen(true);
    let teamData: any;
    for (let i = 0; i < data.allTeamInfo.length; i++) {
      if (data.allTeamInfo[i].id === data.playerInfo[0].team) {
        teamData = data.allTeamInfo[i];
        break;
      }
    }
    setTeamData(teamData);
    setPlayerData(data.playerInfo[0]);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <FormationContainer>
        <Title>
          <strong> Total Points </strong>
          <span> {eventTotalPoints}</span>
        </Title>
        <Row>
          {goalkeepers.map((player: any) => (
            <PlayerCard
              key={player.id}
              player={player}
              isCaptain={player.id === captain}
              onClick={() => {
                handleOpenModal();
              }}
            />
          ))}
        </Row>
        <Row>
          {defenders.map((player: any) => (
            <PlayerCard
              key={player.id}
              player={player}
              isCaptain={player.id === captain}
              onClick={() => {
                handleOpenModal();
              }}
            />
          ))}
        </Row>
        <Row>
          {midfielders.map((player: any) => (
            <PlayerCard
              key={player.id}
              player={player}
              isCaptain={player.id === captain}
              onClick={() => {
                handleOpenModal();
              }}
            />
          ))}
        </Row>
        <Row>
          {forwards.map((player: any) => (
            <PlayerCard
              key={player.id}
              player={player}
              isCaptain={player.id === captain}
              onClick={() => {
                handleOpenModal();
              }}
            />
          ))}
        </Row>
        <BenchContainer>
          <BenchText>
            <strong>Bench Points</strong>
            <span> {benchTotalPoints}</span>
          </BenchText>
          <Row>
            {bench.map((player: any) => (
              <PlayerCard key={player.id} player={player} />
            ))}
          </Row>
        </BenchContainer>
      </FormationContainer>
    </Container>
  );
};

export default ManagerSquad;

const Container = styled.div`
  width: 60%;
  display: flex;
  /* justify-content: right; */

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Title = styled.div`
  text-align: left;
  color: ${colors.textBlack};
  margin: 1rem 0;
  z-index: 1;
  font-size: ${fontSize.media_large.large};

  span {
    font-size: ${fontSize.media_large.large};
  }
`;

const FormationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-left: 1rem;
  width: 100%;
  /* max-width: 800px; */
  position: relative;
  border-radius: 16px;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.5);
  overflow: hidden; /* 가상 요소가 넘치지 않도록 설정 */
  background: linear-gradient(135deg, rgba(0, 128, 0, 0.5), rgba(50, 205, 50, 0.5)); // gradient green

  /* 내부 콘텐츠 스타일 */
  z-index: 1;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  & > * {
    margin: 0 5px;
  }
`;

const BenchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`;

const BenchText = styled(Title)``;
